import dayjs from 'dayjs'

export const timestampTemplate = 'YYYY-MM-DD HH:mm:ss'
const onlyTimeTemplate = 'HH:mm'
export function sliceTime (startTimeString, endTimeString, duration) {
  const [startTime, endTime] = dayjs(startTimeString, timestampTemplate)
    .isBefore(dayjs(endTimeString, timestampTemplate))
    ? [dayjs(startTimeString, timestampTemplate), dayjs(endTimeString, timestampTemplate)]
    : [dayjs(endTimeString, timestampTemplate), dayjs(startTimeString, timestampTemplate)]

  const durationD = dayjs.duration(duration)
  const res = []
  let temp = startTime

  while (temp.isBefore(endTime)) {
    res.push(temp.format(onlyTimeTemplate))
    temp = temp.add(durationD)
  }
  res.push(endTime.format(onlyTimeTemplate))
  return res
}

export async function getTimeSlotForDate (date, setting) {
  const isoWeek = require('dayjs/plugin/isoWeek')
  dayjs.extend(isoWeek)
  const targetDayOfWeek = dayjs(date).isoWeekday()
  const duration = setting.gap
  return (setting.weeklySettings
    .find(it => parseInt(it.dayOfWeek) === targetDayOfWeek)?.openingTimespan ?? [])
    .map(it => sliceTime(date + ' ' + it.from, date + ' ' + it.to, duration))
    .flat()
}
